.categoria__icone{
	width: 100px;
	height: 100px;
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;
	background-color: #8651A3;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin-bottom: 8px;
	transition: all 0.3s linear;
}
.categoria__titulo{
	font-family: 'poppinsbold';
	font-size: 16px;
	line-height: 20px;
	color: #8551A3;
	text-align: center;
	transition: all 0.3s linear;
}
.categoria__item{
	display: block;

	&:hover{
		text-decoration: none;

		.categoria__icone{
			background-color: #4EB943;
		}
		.categoria__titulo{
			color: #4EB943;
		}
	}
	&.categoria__item--active{
		.categoria__icone{
			background-color: #4EB943;
		}
		.categoria__titulo{
			color: #4EB943;
		}
	}
}
.categorias__listagem{
	padding-bottom: 34px;
	border-bottom: 7px solid #FF2B5F;
	margin-bottom: 30px;
}
.restrita__card{
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}
.restrita__icone{
	width: 58px;
	height: 58px;
	min-width: 58px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: #FF2B5F;
}
.restrita__titulo{
	font-family: 'poppinsbold';
	font-size: 16px;
	line-height: 20px;
	color: #999999;
	margin-bottom: 4px;
}
.restrita__item__desc{
	text-align: justify;
	color: #999999;
}
.restrita__texto{
	margin-right: 100px;
}
.restrita__btn{
	width: 124px;
	min-width: 124px;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 56px;
	background-color: #FF2B5F;
	color: #fff;
	font-family: 'poppinsbold';
	font-size: 14px;
	line-height: 14px;
	transition: all 0.3s linear;

	&:hover{
		color: #fff;
		text-decoration: none;
		background-color: #4EB943;
	}

	.fas{
		margin-left: 5px;
	}
}
.paginacao__item{
	display: flex;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: #8651A3;
	color: #fff;
	align-items: center;
	justify-content: center;
	margin: 5px 5px;
	font-family: 'poppinsbold';
	font-size: 15px;
	line-height: 15px;
	color: #fff;
	transition: all 0.3s linear;

	&.paginacao__item--active{
		background-color: #4EB943;
		color: #fff;
	}

	&:hover{
		background-color: #4EB943;
		color: #fff;
		text-decoration: none;
	}
}
.paginacao__custom{
	display: flex;
	justify-content: center;
	padding-top: 20px;
	flex-wrap: wrap;
}
.restrita__sair{
	padding-top: 30px;
	display: flex;
	justify-content: center;
}
.restrita__sair__btn{
	display: inline-flex;
	align-items: center;
	padding: 15px 25px;
	border-radius: 20px;
	background-color: #FF2B5F;
	color: #fff;
	font-family: 'poppinsbold';
	font-size: 15px;
	line-height: 15px;
	transition: all 0.3s linear;

	&:hover{
		background-color: #4EB943;
		color: #fff;
		text-decoration: none;
	}

	&:after{
		content: '\f2f5';
		display: inline-block;
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		font-size: 18px;
		line-height: 18px;
		margin-left: 10px;
	}
}
@include media-breakpoint-up (lg) {
	.categorias__listagem{
		display: flex;
		flex-wrap: wrap;

		.categoria__item{
			width: calc(100% / 6);
			max-width: 100%;
		}
	}
}
@include media-breakpoint-down (md) {
	.categorias__listagem{
		display: flex;
		flex-wrap: wrap;

		.categoria__item{
			width: calc(100% / 3);
			max-width: 100%;
			margin-bottom: 15px;
		}
	}
	.restrita__texto{
		margin-right: 20px;
	}
	.restrita__item__desc{
		margin-bottom: 20px;
	}
	.restrita__titulo{
		margin-bottom: 10px;
	}
}
@include media-breakpoint-down (sm) {
	.restrita__card{
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin-bottom: 30px;
		padding-bottom: 30px;
		border-bottom: 4px solid rgba(#FF2B5F, 0.5);

		.restrita__texto{
			margin-right: 0px;
		}
		.restrita__icone{
			margin-bottom: 10px;
			margin-right: 0px;
		}
	}
}
@include media-breakpoint-down (xs) {
	.categorias__listagem{
		display: flex;
		flex-wrap: wrap;

		.categoria__item{
			width: calc(100% / 2);
			max-width: 100%;
			margin-bottom: 15px;
			padding-right: 10px;
			padding-left: 10px;
		}
	}
}
