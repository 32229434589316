.solucoes__card{
	width: 100%;
	padding: 64px 60px 46px 60px;
	border-radius: 36px;
	background-color: #5DBE53;
}
.solucoes__titulo{
	font-family: 'poppinsbold';
	font-size: 30px;
	line-height: 34px;
	text-align: center;
	color: #fff;
	padding-bottom: 20px;
	position: relative;
	margin-bottom: 16px;

	&:after{
		width: 168px;
		height: 14px;
		content: '';
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0px;
		background-image: url('../images/solucao__detalhe.png');
		background-repeat: no-repeat;
		background-position: center;
		background-repeat: no-repeat;
	}
}
.solucoes__segura__btn{
	display: flex;
	justify-content: center;
}
.solucao__btn{
	width: 124px;
	height: 56px;
	border-radius: 56px;
	background-color: #FF2B5F;
	color: #fff;
	font-family: 'poppinsbold';
	font-size: 14px;
	line-height: 14px;
	border: none;
	transition: all 0.3s linear;

	&:hover{
		background-color: #fff;
		color: #FF2B5F;
		text-decoration: none;
	}

	&:focus{
		outline: none;
	}
}
.solucoes__interna__titulo{
	font-family: 'poppinsbold';
	font-size: 20px;
	line-height: 24px;
	color: #FF2B5F;
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 40px;

	&:after{
		content: '';
		width: auto;
		max-width: 100%;
		flex-grow: 1;
		height: 7px;
		background-color: #FF2B5F;
		margin-left: 25px;
	}
}
.solucao__row{
	padding-top: 176px;
	position: relative;

	&:before{
		width: 291px;
		height: 163px;
		position: absolute;
		content: '';
		left: 50%;
		transform: translateX(-50%);
		top: 0px;
		background-image: url('../images/solucoes__bracos.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
}
.solucao__bola{
	width: 184px;
	height: 184px;
	display: flex;
	border-radius: 50%;
	background-color: #5DBE53;
	font-family: 'poppinsbold';
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	min-width: 184px;
	margin-right: auto;
	margin-left: auto;
	color: #fff;
	padding: 20px 10px 10px 10px;
	margin-bottom: 15px;
}
.solucao__bola__numero{
	font-size: 80px;
	line-height: 50px;
}
.solucao__bola__destaque{
	font-size: 50px;
	line-height: 40px;
}
.solucao__bola__conteudo{
	font-size: 20px;
	line-height: 20px;
	text-shadow: 2px 0 0 #5DBE53, -2px 0 0 #5DBE53, 0 2px 0 #5DBE53, 0 -2px 0 #5DBE53, 1px 1px #5DBE53, -1px -1px 0 #5DBE53, 1px -1px 0 #5DBE53, -1px 1px 0 #5DBE53;
	margin-top: -10px;
}
.solucao__bola__desc{
	text-align: center;
	margin-bottom: 20px;
}
.solucao__conteudo__img{
	overflow: hidden;
	border-radius: 25px;
}
.solucao__row__2{
	position: relative;
	padding-top: 110px;

	&:before{
		content: '';
		position: absolute;
		width: 539px;
		height: 152px;
		top: 0px;
		left: 50%;
		transform: translateX(-50%);
		background-image: url('../images/detalhe__row__2.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
}
.col__solucoes{
	margin-bottom: 40px;
}
.solucoes__parceiros{
	padding: 40px 0px;
	background-color: #8D5BA8;
	color: #fff;
	position: relative;
	margin-bottom: -30px;

	&:after{
		width: 100%;
		height: 270px;
		content: '';
		position: absolute;
		z-index: -1;
		top: 100%;
		left: 0px;
		background-color: #8d5ba8;
	}
}
.titulo__parceiros{
	color: #fff;
	font-family: 'poppinsbold';
	font-size: 50px;
	line-height: 54px;
	margin-bottom: 5px;
}
.sub__parceiros{
	font-size: 18px;
	line-height: 22px;
	color: #fff;
}
.owl-carousel.parceiros__owl{
	.owl-nav{
		button.owl-prev,
		button.owl-next{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;
			border-radius: 50%;
			background-color: #4EB943;
			box-shadow: 0px 0px 0px 0px rgba(#4EB943, 0.35);
			margin: 0px 8px;
			transition: all 0.3s linear;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);


			&:hover{
				box-shadow: 0px 0px 0px 7px rgba(#4EB943, 0.35);
			}
			&:focus{
				outline: none;
			}
		}
		button.owl-prev{
			left: 0px;
		}
		button.owl-next{
			right: 0px;
		}
	}
	.owl-dots{
		display: none;
	}
}
.parceiros__card{
	background-color: #fff;
	width: 100%;
	max-width: 180px;
	border-radius: 20px;
	overflow: hidden;
	display: block;
	margin-right: auto;
	margin-left: auto;
}
@include media-breakpoint-only (lg) {
	.solucao__row__2{
		position: relative;
		padding-top: 110px;

		&:before{
			content: '';
			position: absolute;
			width: 350px;
			height: 152px;
			top: 0px;
			left: 50%;
			transform: translateX(-50%);
			background-image: url('../images/detalhe__row__2.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: center;
		}
	}
	.solucao__row{
		padding-top: 176px;
		position: relative;

		&:before{
			width: 250px;
			height: 163px;
			position: absolute;
			content: '';
			left: 50%;
			transform: translateX(-50%);
			top: 0px;
			background-image: url('../images/solucoes__bracos.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100% 100%;
		}
	}
}
@include media-breakpoint-down (md) {
	.solucao__conteudo__img{
		max-width: 538px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 30px;
	}
	.solucao__row{
		max-width: 590px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
	}
	.solucao__row__2{
		max-width: 620px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
	}
	.parceiros__titulo{
		margin-bottom: 20px;
	}
}
@include media-breakpoint-down (sm) {
	.solucao__bola{
		width: 120px;
		height: 120px;
		display: flex;
		min-width: 120px;
		margin-right: auto;
		margin-left: auto;
		color: #fff;
		padding: 20px 10px 10px 10px;
		margin-bottom: 15px;
	}
	.solucao__bola__numero,
	.solucao__bola__destaque{
		font-size: 38px;
		line-height: 36px;
	}
	.solucao__bola__conteudo{
		font-size: 16px;
		line-height: 14px;
	}
	.solucao__row__2{
		position: relative;
		padding-top: 100px;

		&:before{
			content: '';
			position: absolute;
			width: 500px;
			height: 100px;
			top: 0px;
			left: 50%;
			transform: translateX(-50%);
			background-image: url('../images/detalhe__row__2.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: center;
		}
	}
	.solucao__row{
		padding-top: 100px;
		position: relative;

		&:before{
			width: 250px;
			height: 100px;
			position: absolute;
			content: '';
			left: 50%;
			transform: translateX(-50%);
			top: 0px;
			background-image: url('../images/solucoes__bracos.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100% 100%;
		}
	}
	.solucoes__titulo{
		font-size: 20px;
		line-height: 24px;
	}
}
.solucao__voltar__btn{
	display: flex;
	justify-content: center;
}
