.segura__pesquisa{
	position: fixed;
	z-index: 1000;
	transition: all 0.4s linear;
	max-width: 800px;
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
	top: -200px;

	&.segura__pesquisa--shown{
		top: 50px;
	}
}
.pesquisa__form{
	display: flex;
	width: 100%;
	border-radius: 20px;
	background-color: #fff;
	overflow: hidden;
}
.pesquisa__input{
	display: block;
	width: 100%;
	height: auto;
	padding: 12px 15px 10px 15px;
	background-color: #fff;
	border: none;
}
.btn__pesquisa{
	border: none;
	background-color: #fff;
	font-size: 18px;
	line-height: 22px;
	padding: 0px 10px;

	&:focus{
		outline: none;
	}
}
.bg__menu{
	position: fixed;
	z-index: 999;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	background-color: rgba(#000, 0.6);
	animation: fadeIn 0.4s linear;

	&.hide{
		animation: fadeOut 0.4s linear;
	}
}
.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}
.main__logo,
.mbl__logo{
	display: block;
}
.botao__restrita{
	.login__btn{
		display: flex;
	}
	.logado__btn{
		display: none;
	}

	&.logado{
		.login__btn{
			display: none;
		}
		.logado__btn{
			display: flex;
		}
	}
}
@include media-breakpoint-up (lg) {
	.mbl__topo{
		display: none;
	}
	.main__topo{
		.container{
			display: flex;
			justify-content: space-between;
		}
	}
	.main__menu{
		display: flex;
		justify-content: space-between;
		flex-grow: 1;
		align-items: center;
	}
	.menu__item{
		display: flex;

		&.menu__item--active{
			.menu__link{
				color: #8651A3;

				&:after{
					opacity: 1;
					background-color: #8651A3;
				}
			}
		}
	}
	.main__topo{
		padding: 20px 0px;
	}
	.main__logo{
		display: block;
		margin-right: 40px;
	}
	.menu__link{
		font-family: 'poppinsbold';
		font-size: 14px;
		line-height: 14px;
		color: #fff;
		display: block;
		padding: 12px 0px;
		position: relative;
		transition: all 0.3s linear;

		&:hover{
			color: #8651A3;

			&:after{
				opacity: 1;
				background-color: #8651A3;
			}
			text-decoration: none;

			&:after{
				opacity: 1;
				background-color: #8651A3;
			}
		}

		&:after{
			background-color: #fff;
			height: 6px;
			width: 100%;
			position: absolute;
			bottom: 0px;
			left: 0px;
			content: '';
			opacity: 0;
			transition: all 0.3s linear;
		}
	}
	.main__topo{
		position: absolute;
		width: 100%;
		left: 50%;
		transform: translateX(-50%);
	}
	.topo{
		position: relative;
		z-index: 10;

		&.topo__interna{
			.main__topo{
				position: relative;
				background-color: #EEEEEE;

				.menu__item{
					&.menu__item--active{
						.menu__link{
							color: #8651A3;
						}
					}
				}

				.menu__link{
					color: #999999;

					&:hover{
						color: #8651A3;

						&:after{
							opacity: 1;
							background-color: #8651A3;
						}
						text-decoration: none;

						&:after{
							opacity: 1;
							background-color: #8651A3;
						}
					}
				}
			}
		}
	}
	.main__menu{
		margin-right: 15px;
	}
	.login__btn,
	.botao__virtual,
	.logado__btn,
	.botao__restrita{
		display: flex;
		align-items: center;
		font-family: 'poppinsbold';
		font-size: 14px;
		line-height: 14px;
	}
	.virtual__link{
		padding: 18px 12px;
		border-radius: 25px;
		background-color: #8651A3;
		color: #fff;
		display: inline-block;
		margin: 0px 5px;
		transition: all 0.3s linear;

		&:hover{
			background-color: darken(#8651A3, 10%);
			color: #fff;
			text-decoration: none;
		}
	}
	.login__link{
		background-color: #FF2B5F;
		padding: 18px 12px;
		border-radius: 25px;
		color: #fff;
		display: inline-block;
		margin: 0px 5px;
		transition: all 0.3s linear;

		&:hover{
			background-color: darken(#FF2B5F, 10%);
			color: #fff;
			text-decoration: none;
		}
	}
	.main__toggler{
		border: none;
		background-color: transparent;
		color: #fff;
		font-size: 18px;
		line-height: 18px;
		margin-left: 10px;

		&:focus{
			outline: none;
		}
	}
	.segura__toggler{
		display: flex;
		align-items: center;
	}
}
@include media-breakpoint-only (lg) {
	.main__logo{
		max-width: 140px;
		margin-right: 20px;
	}
	.main__menu{
		margin-right: 10px;

		.menu__link{
			font-size: 13px;
			line-height: 13px;
		}
	}
	.login__link,
	.virtual__link{
		padding: 10px 8px;
	}
	.main__toggler{
		margin-left: 5px;
	}
}
@include media-breakpoint-down (md) {
	.main__topo{
		position: fixed;
		z-index: 1000;
		left: -250px;
		transition: all 0.4s linear;
		top: 0px;
		width: 250px;
		height: 100%;
		overflow-y: auto;
		background-color: #EEEEEE;

		.container{
			padding: 0px;
		}

		&.main__topo--shown{
			left: 0px;
		}
	}
	.main__logo{
		display: block;
		text-align: center;
		padding: 10px;
	}
	.menu__link{
		display: block;
		padding: 6px 10px;
		margin-bottom: 10px;
		font-size: 14px;
		line-height: 14px;
		font-family: 'poppinsbold';
		color: #8651A3;
		position: relative;
		overflow: hidden;

		&:after{
			width: 6px;
			height: 100%;
			content: '';
			position: absolute;
			right: -6px;
			top: 0px;
			background-color: #8651A3;
			transition: all 0.3s linear;
		}

		&:hover{
			text-decoration: none;
			color: #8651A3;

			&:after{
				right: 0px;
			}
		}
	}
	.menu__item{
		&.menu__item--active{
			.menu__link{
				&:after{
					right: 0px;
				}
			}
		}
	}
	.main__menu{
		margin-bottom: 10px;
	}
	.virtual__link{
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px 0px 9px 0px;
		background-color: #8651A3;
		color: #fff;
		font-family: 'poppinsbold';
		width: 100%;

		&:hover{
			text-decoration: none;
			color: #fff;
		}
	}
	.login__link{
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px 0px 9px 0px;
		background-color: #FF2B5F;
		color: #fff;
		font-family: 'poppinsbold';
		width: 100%;

		&:hover{
			text-decoration: none;
			color: #fff;
		}
	}
	.botao__restrita,
	.login__btn,
	.logado__btn{
		display: flex;
		width: 100%;
	}
	.segura__toggler{
		display: none;
	}
	.mbl__topo{
		padding: 10px 0px;
		background-color: #EEEEEE;

		.container{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
	.mbl__toggler{
		border: none;
		background-color: transparent;
		font-size: 24px;
		line-height: 24px;
		color: #000;

		&:focus{
			outline: none;
		}
	}
}
@include media-breakpoint-down (sm) {
	.mbl__logo{
		display: block;
		max-width: 140px;
	}
}
@include media-breakpoint-down (xs) {
	.mbl__logo{
		display: block;
		max-width: 120px;
	}
}
