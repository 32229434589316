.material__interna{
	padding: 60px 0px 50px 0px;
	overflow: hidden;
}
.material__interna__header{
	max-width: 800px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	margin-bottom: 30px;
}
.material__card__titulo{
	min-width: 220px;
	padding: 0px 15px;
	width: auto;
	min-height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: #FF2B5F;
	color: #fff;
	font-family: 'poppinsbold';
	font-size: 16px;
	line-height: 20px;
	border-radius: 70px;
}
.material__card__header{
	display: flex;
	align-items: center;
	margin-bottom: 50px;

	&:after{
		display: block;
		width: auto;
		max-width: 100%;
		height: 7px;
		background-color: #FF2B5F;
		content: '';
		flex-grow: 1;
		margin-left: 5px;
	}
}
.material__card__btn{
	display: flex;
	justify-content: flex-end;
}
.material__card{
	margin-bottom: 70px;
}
.material__detalhe__titulo{
	font-family: 'poppinsbold';
	font-size: 20px;
	line-height: 24px;
	color: #FF2B5F;
	display: flex;
	align-items: center;
	margin-bottom: 35px;

	&:after{
		display: block;
		content: '';
		width: auto;
		max-width: 100%;
		flex-grow: 1;
		background-color: currentColor;
		margin-left: 20px;
		height: 7px;
	}
}
.material__detalhe__btns{
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 34px;
}
.row__materiais{
	padding-top: 40px;
}
.material__input{
	background-color: #999999;
	color: #fff;
	width: 100%;
	display: block;
	padding: 20px 24px 18px 24px;
	border: none;
	border-radius: 40px;
	font-size: 15px;
	line-height: 19px;
	transition: all 0.3s linear;

	&:focus{
		background-color: #8551A3;
		outline: none;
	}

	&::placeholder{
		color: #fff;
	}
}
.formulario__btn{
	display: flex;
	justify-content: center;
	padding-top: 20px;

	.quem__btn{
		border: none;

		&:focus{
			outline: none;
		}
	}
}
@include media-breakpoint-up (lg) {
	.material__card{
		&:nth-child(even) {
			.material__card__conteudo{
				.row{
					.col-lg-6{
						&:first-child{
							order: 2;
						}
					}
					.col-lg-6{
						&:last-child{
							order: 1;
						}
					}
				}
			}
		}
	}
}
@include media-breakpoint-down (md) {
	.material__card__img{
		text-align: center;
		padding-top: 30px;
	}
}
@include media-breakpoint-down (xs) {
	.material__detalhe__btns{
		flex-direction: column;
		align-items: center;
	}
}
