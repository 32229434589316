.whats__fixo{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 54px;
	height: 54px;
	border-radius: 50%;
	background-color: #40FF00;
	color: #FF2B5F;
	position: fixed;
	right: 10px;
	padding-right: 2px;
	bottom: 10px;
	box-shadow: 0px 0px 5px rgba(#000, 0.6);
	transition: all 0.3s linear;

	&:hover{
		box-shadow: 0px 0px 15px rgba(#000, 0.8);
	}
}
.rodape__conteudo{
	padding-top: 70px;
	background-image: url('../images/bg__rodape.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	color: #fff;
}
.rodape{
	padding-top: 270px;
	background-image: url('../images/rodape__detalhe.png');
	background-repeat: no-repeat;
	background-size: 1920px 270px;
	background-position: center top;
}
.titulo__rodape{
	font-family: 'poppinsbold';
	font-size: 14px;
	line-height: 18px;
	display: flex;
	align-items: center;
	position: relative;

	&:after{
		content: '';
		height: 4px;
		background-color: #fff;
		width: auto;
		max-width: 100%;
		display: block;
		flex-grow: 1;
		margin-left: 20px;
	}
}
.sub__rodape{
	font-family: 'poppinsbold';
	font-size: 50px;
	line-height: 54px;
}
.rodape__header{
	max-width: 500px;
	width: 100%;
	position: relative;
	z-index: 15;
	margin-bottom: 30px;

	span{
		display: inline-block;
		position: relative;

		&:before{
			z-index: -1;
			width: calc(100% + 10px);
			height: calc(100% + 10px);
			border-radius: 35px;
			background-color: #8651A3;
			position: absolute;
			content: '';
			left: -5px;
			top: -5px;
		}
	}
}
.contato__input{
	display: block;
	width: 100%;
	font-family: 'poppinsbold';
	font-size: 14px;
	line-height: 18px;
	color: #BABABA;
	height: auto;
	padding: 20px 18px 20px 18px;
	border-radius: 26px;
	border: none;
	resize: none;

	&:focus{
		outline: none;
	}

	&::placeholder{
		color: #BABABA;
	}
}
.segura__textarea{
	display: flex;
	align-items: center;
	background-color: #fff;
	border-radius: 26px;
}
.contato__btn{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 64px;
	min-width: 64px;
	height: 64px;
	border-radius: 50%;
	background-color: #8651A3;
	color: #fff;
	font-size: 28px;
	line-height: 28px;
	margin-right: 15px;
	border: none;
}
.contato__icone{
	width: 50px;
	min-width: 50px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #8651A3;
	color: #fff;
	font-size: 26px;
	line-height: 26px;
	border-radius: 50%;
	margin: 0px 5px;
}
.contato__tel{
	display: flex;
	align-items: center;
	font-size: 17px;
	line-height: 21px;
}
.contato__mail{
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: 17px;
	line-height: 21px;
}
.contato__form{
	margin-bottom: 80px;
}
.contato__sociais{
	position: relative;

	&:before{
		z-index: -1;
		width: 160px;
		height: 145px;
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-image: url('../images/arroba__bg.png');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	.titulo__sociais{
		font-family: 'poppinsbold';
		font-size: 36px;
		line-height: 40px;
		text-align: center;
		margin-bottom: 10px;
	}
	.sociais__nav{
		display: flex;
		justify-content: center;
		font-size: 18px;
		line-height: 18px;

		a{
			display: block;
			margin: 0px 5px;
		}
	}
}
.row__contato{
	padding-bottom: 64px;
	position: relative;
	z-index: 10;
}
.creditos__conteudo{
	padding: 12px 0px 16px 0px;
	border-top: 1px solid rgba(#fff, 0.25);
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.creditos{
	span{
		font-family: 'poppinsbold';
	}
}
.gv8__box{
	display: flex;
	align-items: center;
	padding-top: 2px;

	.gv8__icone{
		margin-left: 5px;
	}
}
@include media-breakpoint-down (md) {
	.contato__form{
		margin-bottom: 20px;
	}
	.contato__tel{
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;
	}
	.contato__icone{
		margin-bottom: 5px;
	}
	.contato__sociais{
		margin-bottom: 20px;
	}
	.contato__mail{
		flex-direction: column;
		align-items: center;
	}
	.rodape{
		padding-top: 160px;
		background-image: url('../images/rodape__detalhe.png');
		background-repeat: no-repeat;
		background-size: 100% 160px;
		background-position: center top;
	}
}
@include media-breakpoint-down (sm) {
	.sub__rodape{
		font-size: 34px;
		line-height: 38px;
		padding-top: 10px;
	}
}
