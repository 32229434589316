/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 30, 2020 */



@font-face {
    font-family: 'baloo_bhaina_2extrabold';
    src: url('baloobhaina2-extrabold-webfont.eot');
    src: url('baloobhaina2-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('baloobhaina2-extrabold-webfont.woff2') format('woff2'),
         url('baloobhaina2-extrabold-webfont.woff') format('woff'),
         url('baloobhaina2-extrabold-webfont.ttf') format('truetype'),
         url('baloobhaina2-extrabold-webfont.svg#baloo_bhaina_2extrabold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'montserratblack';
    src: url('#{$fonts}/montserrat-black/montserrat-black-webfont.eot');
    src: url('#{$fonts}/montserrat-black/montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.svg#montserratblack') format('svg');
    font-weight: normal;
    font-style: normal;

}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 30, 2019 */



@font-face {
    font-family: 'poppinsregular';
    src: url('#{$fonts}/poppins-regular/poppins-regular-webfont.eot');
    src: url('#{$fonts}/poppins-regular/poppins-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/poppins-regular/poppins-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/poppins-regular/poppins-regular-webfont.woff') format('woff'),
         url('#{$fonts}/poppins-regular/poppins-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/poppins-regular/poppins-regular-webfont.svg#poppinsregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 30, 2019 */



@font-face {
    font-family: 'poppinsbold';
    src: url('#{$fonts}/poppins-bold/poppins-bold-webfont.eot');
    src: url('#{$fonts}/poppins-bold/poppins-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/poppins-bold/poppins-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/poppins-bold/poppins-bold-webfont.woff') format('woff'),
         url('#{$fonts}/poppins-bold/poppins-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/poppins-bold/poppins-bold-webfont.svg#poppinsbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'robotoregular';
    font-display: optional;
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot');
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff') format('woff'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
