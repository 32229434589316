.modal-backdrop{
	opacity: 1 !important;
	background: rgba(#000,.5);
	backdrop-filter: blur(4px);
}

.modal_login{
	.modal-header{
		background: linear-gradient(45deg, #8651A3, #FF2B5F, orange);
		color: #FFF;
	}

	.modal-title{
		text-shadow: -1px 1px rgba(#000,.4);
	}
}
