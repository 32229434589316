.select__custom{
	position: relative;
	z-index: 900;

	.output{
		font-size: 15px;
		line-height: 19px;
		height: auto;
		padding: 20px 24px 18px 24px;
		border: none;
		background-color: #999999;
		width: 100%;
		color: #fff;
		display: block;
		cursor: pointer;
		position: relative;
		z-index: 4;
		border-radius: 40px;
		transition: all 0.3s linear;

		&[aria-expanded="true"] {
			background-color: #8551A3;
		}


	    &:before{
	    	content: '';
	    	position: absolute;
	    	right: 20px;
	    	width: 18px;
	    	height: 10px;
	    	background-color: #fff;
	    	top: 50%;
	    	transform: translateY(-50%) rotate(var(--rotate, 0));
	    	transition: all 0.3s linear;
	    	clip-path: polygon(50% 100%, 0 0, 100% 0);
	    }
	}

	&.show{
		--rotate: 90deg;
	}

	.dropdown-menu{
		min-width: 180px;
		width: 100%;
		padding: 5px 20px 5px 20px;
		color: #999999;
		background-color: #E5E5E5;
		overflow: auto;
		max-height: 210px;
		font-size: 14px;
		line-height: 18px;
		border: none;
		z-index: -1;
		border-radius: 30px 30px 30px 30px;

		li{
			padding: 10px 10px 8px 10px;
			border-bottom: 1px solid #999999;

			&:last-child{
				border-bottom: none;
			}

			&.selected{
				background: rgba(#999999, 0.5);
				color: #fff;
			}

			&:not(.selected) {
				&:not(.disabled):hover{
					text-decoration: underline;
				}
			}


			&:not(.disabled):hover{
				cursor: pointer;
			}

			&.disabled{
				color: rgba(#000, 0.2);

				&:hover{
					cursor: default;
				}
			}
		}
	}
}

.custom-select-loaded{
	display: none !important;
}
