.sobre__interna{
	padding: 56px 0px 20px 0px;
}
.sobre__interna__header{
	max-width: 790px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	margin-bottom: 20px;
}
.titulo__interna{
	font-family: 'poppinsbold';
	font-size: 50px;
	line-height: 54px;
	color: #8551A3;
	margin-bottom: 5px;
}
.titulo__comentario{
	display: flex;
	align-items: center;
	font-family: 'poppinsbold';
	font-size: 20px;
	line-height: 24px;
	color: #FF2B5F;
	margin-bottom: 50px;

	&:after{
		display: block;
		content: '';
		height: 7px;
		width: auto;
		max-width: 100%;
		flex-grow: 1;
		background-color: currentColor;
		margin-left: 25px;
	}
}
.comentario__card{
	display: flex;
	align-items: center;
	max-width: 830px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
.comentario__img{
	border-radius: 50%;
	margin-right: 24px;
	overflow: hidden;
	min-width: 154px;
}
.comentario__desc{
	text-align: justify;
	margin-bottom: 5px;
}
.comentario__autor{
	font-family: 'poppinsbold';
	font-size: 14px;
	line-height: 18px;
	color: #999999;
}
.comentario__prev,
.comentario__next{
	font-size: 24px;
	line-height: 24px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
.comentario__prev{
	left: -30px;
}
.comentario__next{
	right: -30px;
}
.comentario__carousel{
	max-width: 830px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
.segura__comentario__carousel{
	padding: 40px 30px 0px 30px;
}
@include media-breakpoint-down (sm) {
	.titulo__interna{
		font-size: 34px;
		line-height: 38px;
	}
}
@include media-breakpoint-down (xs) {
	.comentario__card{
		flex-direction: column;
		align-items: center;
	}
	.comentario__img{
		margin-right: 0px;
		margin-bottom: 10px;
	}
	.segura__comentario__carousel{
		padding: 0px 20px;
	}
	.comentario__prev{
		left: -20px;
	}
	.comentario__next{
		right: -20px;
	}
}
