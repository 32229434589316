.login__titulo{
	display: flex;
	align-items: center;
	font-family: 'poppinsbold';
	font-size: 20px;
	line-height: 24px;
	color: #FF2B5F;
	margin-bottom: 50px;

	&:after{
		content: '';
		display: block;
		width: auto;
		max-width: 100%;
		flex-grow: 1;
		background-color: #FF2B5F;
		height: 7px;
		margin-left: 20px;
	}
}
.login__form{
	width: 100%;
	max-width: 430px;
	margin-right: auto;
	margin-left: auto;
}
.login__input{
	padding: 26px;
	width: 100%;
	display: block;
	border-radius: 72px;
	border: none;
	background-color: #8551A3;
	font-family: 'poppinsbold';
	font-size: 16px;
	line-height: 20px;
	color: #fff;

	&:focus{
		outline: none;
	}

	&::placeholder{
		color: #fff;
	}
}
.segura__login__btn{
	display: flex;
	justify-content: center;
	padding-top: 15px;
}
.enviar__btn{
	display: flex;
	width: 124px;
	height: 56px;
	align-items: center;
	justify-content: center;
	border-radius: 56px;
	background-color: #FF2B5F;
	color: #fff;
	font-family: 'poppinsbold';
	font-size: 16px;
	line-height: 16px;
	border: none;

	&:focus{
		outline: none;
	}
}
