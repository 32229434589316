.btn-pill{
	border-radius: 2rem;
}

.btn-login{
	background: #8651A3;
	color: #FFF;
	position: relative;
	padding: 1rem 2rem;
	border-radius: 2rem;
	perspective: 3px;
	overflow: hidden;
	border: none;

	&::before{
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #FF2B5F;
		z-index: -1;
		clip-path: circle(var(--w, 0) at 50% 50%);
		transition: clip-path .4s linear;
	}

	&:hover{
		color: #FFF;
		--w: 100%;
	}
}

.btn-enviar{
	padding: .75rem 2rem;
	border-radius: 2rem;
	background: linear-gradient(45deg, #8651A3, #FF2B5F, orange);
	color: #FFF;
	width: 100%;
	max-width: 200px;
	text-shadow: -1px 1px rgba(#000,.4);
	border: none;

	&:hover{
		color: #FFF;
		box-shadow: 0 0 10px rgba(#000,.8);
	}
}
