// bg-position(x,y) width height
$icons: (
	'banner__left': -113px -30px 27px 13px,
	'banner__right': -113px -53px 27px 13px,
	'detalhe-depoimento-3': 0px 0px 53px 42px,
	'gv8__icone': -113px 0px 39px 20px,
	'megafone-agenda-1': -84px -52px 19px 18px,
	'pag__left': -113px -76px 14px 10px,
	'pag__right': -137px -76px 14px 10px,
	'solucoes__next': 0px -96px 27px 13px,
	'solucoes__prev': -37px -96px 27px 13px,
	'stardust': -43px -52px 31px 31px,
	'telemarketing-sm': 0px -52px 33px 34px,
	'telemarketing': -63px 0px 40px 42px,
);

@if $nativeCssIconVarialables {
	:root{
		@each $icon,$value in $icons {
			/*icon-#{$icon}*/
			--icon-#{$icon}-width: #{nth($value, 3)};
			--icon-#{$icon}-height: #{nth($value, 4)};
			--icon-#{$icon}-bg: #{nth($value, 1)} #{nth($value, 2)};
		}
	}
}

.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	background-size: 152px 109px;

	display: inline-block;
	vertical-align: middle;

}

.icon__mask{
	mask-image: url('../images/sprites.png');
	mask-repeat:no-repeat;
	mask-size: 152px 109px;

	display: inline-block;
	vertical-align: middle;

}

@each $icon,$value in $icons {
	.#{$icon} {
		width: nth($value,3);
		height: nth($value,4);
		$sprite-position: #{nth($value,1)} #{nth($value,2)};

		&.icon{
			background-position: $sprite-position;
		}

		&.icon__mask{
			mask-position: $sprite-position;
		}
	}
}
