.beneficios__item{
	margin-bottom: 50px;
}
.beneficios__titulo{
	margin-bottom: 10px;
	font-family: 'poppinsbold';
	color: #FF2B5F;
	font-size: 30px;
	line-height: 34px;
	display: flex;
	flex-direction: column;

	&:before{
		content: '';
		display: block;
		width: 62px;
		height: 8px;
		background-color: #FF2B5F;
		margin-bottom: 10px;
	}
}
.beneficios__btn{
	display: flex;
	justify-content: center;
}
@include media-breakpoint-up (lg) {
	.beneficios__item{
		&:nth-child(even) {
			.col-lg-6{
				&:first-child{
					order: 2;
				}
				&:last-child{
					order: 1;

					.beneficios__titulo{
						align-items: flex-end;
						text-align: right;
						justify-content: flex-end;
					}
				}
			}
		}
	}
}
@include media-breakpoint-down (md) {
	.beneficios__item{
		.beneficios__img{
			text-align: center;
			margin-bottom: 30px;
		}
	}
}
