.banner__carousel{
	max-width: 1920px;
	width: 100%;
	position: relative;
	margin-right: auto;
	margin-left: auto;
	overflow: hidden;
}
.banner__inner{
	max-width: 1920px;
	width: 160%;
	position: relative;
	left: 50%;
	transform: translateX(-50%);

	&:after{
		z-index: 20;
		content: '';
		width: 100%;
		height: 275px;
		background-image: url('../images/detalhe__banner.png');
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: 1920px 275px;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0px;
		position: absolute;
	}
}
.banner__controls{
	position: absolute;
	left: 10%;
	bottom: 220px;
	display: inline-flex;

	a{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 48px;
		height: 48px;
		border-radius: 50%;
		background-color: #4EB943;
		box-shadow: 0px 0px 0px 0px rgba(#4EB943, 0.35);
		margin: 0px 8px;
		transition: all 0.3s linear;

		&:hover{
			box-shadow: 0px 0px 0px 7px rgba(#4EB943, 0.35);
		}
	}
}
.quem__somos{
	padding: 35px 0px 56px 0px;
}
.titulo__quem{
	font-family: 'poppinsbold';
	font-size: 50px;
	line-height: 54px;
	color: #FF2B5F;
	margin-bottom: 20px;
}
.quem__desc{
	text-align: justify;
	margin-bottom: 25px;
}
.quem__item{
	padding-left: 40px;
	position: relative;
	margin-bottom: 20px;

	&:before{
		content: '';
		position: absolute;
		top: 2px;
		left: 5px;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		background-color: #8D5BA8;
	}
}
.quem__item__titulo{
	font-family: 'poppinsbold';
	font-size: 14px;
	line-height: 18px;
	color: #8D5BA8;
}
.quem__item__desc{
	font-size: 13px;
	line-height: 17px;
	text-align: justify;
}
.quem__btn{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 206px;
	min-height: 70px;
	height: auto;
	border-radius: 70px;
	background-color: #FF2B5F;
	color: #fff;
	font-family: 'poppinsbold';
	text-align: center;
	margin: 8px;
	padding: 7px 5px 5px 5px;
	transition: all 0.3s linear;

	&:hover{
		background-color: #5DBE53;
		color: #fff;
		text-decoration: none;
	}
}
.quem__btns{
	display: flex;
	padding-top: 10px;
	position: relative;

	&:after{
		content: '';
		position: absolute;
		width: 56px;
		height: 23px;
		background-image: url('../images/detalhe__sobre.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		bottom: -40px;
		right: 20px;
	}
}
.quem__img{
	position: relative;

	&:before{
		content: '';
		position: absolute;
		width: 56px;
		height: 23px;
		background-image: url('../images/detalhe__sobre.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		bottom: 286px;
		left: -15px;
	}
}
.quem__header{
	position: relative;

	&:after{
		content: '';
		position: absolute;
		width: 21px;
		height: 24px;
		top: -30px;
		right: 0px;
		background-image: url('../images/detalhe__sobre2.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 21px 24px;
	}
}
.solucoes{
	position: relative;
	padding: 70px 0px 0px 0px;
	background-image: url('../images/bg__solucoes.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	color: #fff;
}
.titulo__solucoes{
	font-family: 'poppinsbold';
	font-size: 14px;
	line-height: 18px;
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 10px;

	&:after{
		content: '';
		display: block;
		width: auto;
		max-width: 100%;
		flex-grow: 1;
		height: 5px;
		background-color: #fff;
		margin-left: 22px;
		margin-right: 50px;
	}
	&:before{
		content: '';
		width: 21px;
		height: 24px;
		position: absolute;
		right: 0px;
		top: 50%;
		transform: translateY(-50%);
		background-image: url('../images/solucoes__detalhe2.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

	}
}
.sub__solucoes{
	font-family: 'poppinsbold';
	font-size: 50px;
	line-height: 54px;
	margin-bottom: 10px;
}
.solucoes__desc{
	text-align: justify;
	margin-bottom: 20px;
}
.solucoes__item{
	display: block;
	max-width: 456px;
	width: 100%;
	margin-left: 30px;
	margin-right: 40px;
	margin-top: 30px;
	background-image: url('../images/bg__solucoes__card.png');
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
	padding: 50px 90px 40px 60px;
	position: relative;

	&:after{
		content: '';
		position: absolute;
		width: 103px;
		height: 104px;
		background-image: url('../images/foguete__icone.png');
		background-repeat: no-repeat;
		background-size: 103px 104px;
		background-position: center;
		right: 5px;
		top: -30px;
	}
	&:before{
		content: '';
		position: absolute;
		width: 84px;
		height: 88px;
		background-image: url('../images/aviao__detalhe.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 84px 88px;
		right: -40px;
		bottom: 0px;
	}
}
.solucoes__icone{
	position: absolute;
	top: -22px;
	left: -20px;
	width: 77px;
	height: 77px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: #8D5BA8;
	overflow: hidden;
	border: 5px solid #A77CBE;
}
.solucoes__item__titulo{
	font-family: 'poppinsbold';
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 10px;
}
.solucoes__item__desc{
	font-size: 13px;
	line-height: 17px;
	margin-bottom: 10px;
}
.solucoes__btn{
	display: inline-block;
	padding-bottom: 10px;
	position: relative;
	font-family: 'poppinsbold';
	transition: all 0.3s linear;

	&:hover{
		text-decoration: none;
		color: #4EB943;

		&:after{
			background-color: #4EB943;
		}
	}

	&:after{
		width: 100%;
		content: '';
		position: absolute;
		bottom: 0px;
		left: 0px;
		background-color: #fff;
		height: 6px;
		transition: all 0.3s linear;
	}
}
.solucoes__controls{
	display: flex;
	align-items: center;
	padding-top: 36px;
	margin-bottom: 30px;
}
.solucoes__carousel.carousel{
	position: relative;
}
.solucoes__controls__detalhe{
	width: 100%;
	max-width: auto;
	flex-grow: 1;
	height: 6px;
	background-color: #fff;
	border-radius: 6px;
	margin-top: 5px;
	margin-right: 14px;
	margin-left: 14px;
}
.solucoes__img{
	height: 100%;
	display: flex;
	align-items: flex-end;
}
.ensino{
	padding: 70px 0px 40px 0px;
	overflow: hidden;

	.row{
		margin-bottom: 40px;
	}
}
.ensino__card{
	max-width: 300px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}
.ensino__figure{
	max-width: 100%;
	width: 184px;
	margin-right: auto;
	margin-left: auto;
	position: relative;
	margin-bottom: 20px;
}
.ensino__imagem{
	width: 184px;
	height: 184px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #5DBE53;
}
.ensino__numero{
	width: 52px;
	height: 52px;
	border: 3px solid #fff;
	background-color: #5DBE53;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'montserratblack';
	font-size: 20px;
	line-height: 20px;
	position: absolute;
	left: 0px;
	top: 0px;
	border-radius: 50%;
	color: #fff;
}
.titulo__ensino{
	text-align: center;
	font-family: 'poppinsbold';
	font-size: 50px;
	line-height: 54px;
	color: #8551A3;
	margin-bottom: 50px;
	max-width: 650px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
.ensino__card__titulo{
	font-family: 'poppinsbold';
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: #8D5BA8;
	padding-bottom: 15px;
	position: relative;
	margin-bottom: 5px;

	&:after{
		width: 120px;
		height: 9px;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0px;
		content: '';
		position: absolute;
		background-image: url('../images/onda.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
}
.ensino__card__desc{
	text-align: justify;
	color: #AAAAAA;
}
@include media-breakpoint-up (lg) {
	.ensino{
		.col-lg-4{
			&:nth-child(1) {
				.ensino__card{
					position: relative;

					&:before{
						content: '';
						position: absolute;
						width: 247px;
						height: 64px;
						background-image: url('../images/ensino__detalhe.png');
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
						top: 126px;
						left: -72px;
						z-index: -1;
					}
					&:after{
						content: '';
						position: absolute;
						width: 221px;
						height: 49px;
						background-image: url('../images/ensino__detalhe__2.png');
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center;
						top: 34px;
						right: -158px;
						z-index: -1;
					}
				}
			}
			&:nth-child(3) {
				.ensino__card{
					position: relative;

					&:before{
						content: '';
						position: absolute;
						width: 236px;
						height: 52px;
						background-image: url('../images/ensino__detalhe__3.png');
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
						top: 112px;
						left: -178px;
						z-index: -1;
					}
					&:after{
						content: '';
						position: absolute;
						width: 235px;
						height: 62px;
						background-image: url('../images/ensino__detalhe__4.png');
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
						right: -80px;
						top: 30px;
						z-index: -1;
					}
				}
			}
		}
	}
}
@include media-breakpoint-only (xl) {
	.solucoes{
		.row{
			.col-lg-6{
				position: relative;

				&:first-child{
					z-index: 10;
				}
				&:last-child{
					z-index: 9;
					transform: translateX(-50px);
				}
			}
		}
	}
}
@include media-breakpoint-only (lg) {
	.banner__inner{
		&:after{
			z-index: 20;
			content: '';
			width: 100%;
			height: 160px;
			background-image: url('../images/detalhe__banner.png');
			background-repeat: repeat-y;
			background-position: center bottom;
			background-size: 100% 100%;
			left: 50%;
			transform: translateX(-50%);
			bottom: 0px;
			position: absolute;
		}
	}
	.banner__controls{
		bottom: 150px;
	}

}
@include media-breakpoint-down (md) {
	.banner__inner{
		&:after{
			z-index: 20;
			content: '';
			width: 100%;
			height: 140px;
			background-image: url('../images/detalhe__banner.png');
			background-repeat: repeat-y;
			background-position: center bottom;
			background-size: 100% 100%;
			left: 50%;
			transform: translateX(-50%);
			bottom: 0px;
			position: absolute;
		}
	}
	.banner__controls{
		bottom: 100px;
	}
	.quem__somos{
		.row{
			.col-lg-5{
				order: 2;
			}
			.col-lg-7{
				order: 1;
				margin-bottom: 50px;
			}
		}
	}
	.quem__img{
		text-align: center;
	}
	.solucoes{
		background-image: url('../images/solucoes__bg__2.jpg');
	}
	.solucoes__carousel{
		.solucoes__item{
			margin-right: auto;
			margin-left: auto;
		}
	}
	.solucoes__img{
		text-align: center;
		justify-content: center;
	}
	.ensino__card{
		margin-bottom: 20px;
	}
}
@include media-breakpoint-down (sm) {
	.banner__inner{
		&:after{
			z-index: 20;
			content: '';
			width: 100%;
			height: 100px;
			background-image: url('../images/detalhe__banner.png');
			background-repeat: repeat-y;
			background-position: center bottom;
			background-size: 100% 100%;
			left: 50%;
			transform: translateX(-50%);
			bottom: 0px;
			position: absolute;
		}
	}
	.banner__controls{
		bottom: 60px;

		a{
			width: 40px;
			height: 40px;
		}
	}
	.titulo__quem{
		font-size: 34px;
		line-height: 38px;
	}
	.titulo__ensino{
		font-size: 34px;
		line-height: 38px;
	}
	.sub__solucoes{
		font-size: 34px;
		line-height: 38px;
	}
	.solucoes__item{
		&:after,
		&:before{
			display: none;
		}
	}
	.solucoes__carousel.carousel{
		.carousel-item{
			padding-left: 20px;

			.solucoes__item{
				margin-left: 0px;
				margin-right: 0px;
				padding: 56px 30px 40px 30px;
			}
		}
	}
}
@include media-breakpoint-down (xs) {
	.quem__btns{
		flex-direction: column;
		align-items: center;
		display: flex;
	}
	.quem__img{
		&:before{
			display: none;
		}
	}
}
