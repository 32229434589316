.md__input{
	position: relative;

	.form-control{
		padding: .5rem 1.25rem;
		height: 48px;
		border-radius: 2rem;
		border:2px solid #CCC;
		background-clip: unset;
		position: relative;
		z-index: 0;

		&::placeholder{
			color: transparent;
		}
	}

	.form-control:focus,
	.form-control:not(:placeholder-shown) {
		border-color: var(--context, $primary);

		& ~ .md__input__label {
			--font-size: 12px;
			--top: 0;
		}
	}

	.md__input__label{
		position: absolute;
		z-index: 1;
		left: calc(1.25rem - 2px);
		font-size: var(--font-size, 1rem);
		top: var(--top, 24px);
		transform: translateY(-50%);
		margin: 0;
		color: #232323;
		transition: all .3s linear;
		background: #FFF;
		padding-left: 4px;
		padding-right: 4px;
	}
}
